<template>
  <div class="d-flex align-center mb-8">
    <div>
      <img
        src="~@/v2/assets/images/download.png"
        class="doc-img"
      />
    </div>
    <div class="flex-grow-1 pl-4">
      <div class="font-weight-bold text-3xl">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckoutPageTitle',
  props: {
    title: {
      default: null,
    },
  },
  data: () => ({}),
}
</script>

<style lang="scss">
.doc-img {
  height: 70px;
  width: auto;
}
</style>
