<template>
  <div>
    <div
      class="py-4"
      v-if="local"
    >
      <p>
        Enter your prepaid or coupon code below. Only one code can be applied
        per purchase.
      </p>
      <v-text-field
        class="promo"
        v-model="local.code"
        v-bind="textField"
        placeholder="ENTER CODE"
        :error-messages="
          showErrors.code && errorMessages.code ? errorMessages.code : null
        "
        @input="submitError = null"
      />

      <div class="d-flex align-center">
        <v-btn
          text
          @click="close"
          >{{ closeLabel }}</v-btn
        >
        <v-spacer />
        <v-btn
          v-bind="buttonType"
          depressed
          @click="submit"
          >Submit</v-btn
        >
      </div>
      <v-input
        :error-messages="
          showErrors.submit && errorMessages.submit
            ? errorMessages.submit
            : null
        "
      />
    </div>
  </div>
</template>

<script>
import { isEqual } from 'lodash'

import { functions } from '@/plugins/firebase'
import NotificationBox from '@/v2/components/ui/NotificationBox'

export default {
  name: 'PurchaseDiscountCode',
  components: { NotificationBox },
  props: {
    closeLabel: {
      default: 'Cancel',
    },
  },
  data: () => ({
    local: null,
    showErrors: {
      code: false,
      submit: true,
    },
    processing: false,
    submitError: null,
  }),
  async mounted() {
    await this.$store.dispatch('app/load_promo_codes')
    let code = this.$store.getters['app/discount_code']
    this.local = { code: code?.code || '' }
  },
  methods: {
    async submit() {
      this.processing = true
      this.submitError = null
      if (this.isValid) {
        if (this.promoCode) {
          this.$store.commit('app/SET_DISCOUNT_CODE', {
            ...this.localFormatted,
            type: 'promocode',
            price: this.promoCode.amount,
          })
          this.next()
        } else if (this.isPrepaidFormat) {
          let code = await this.$store.dispatch(
            'app/get_prepaid_doc',
            this.localFormatted.code
          )
          if (!code || !code.active) {
            this.submitError = 'Code is missing or expired'
          } else {
            this.$store.commit('app/SET_DISCOUNT_CODE', {
              ...this.localFormatted,
              type: 'prepaid',
              price: code.price || 0,
            }),
              this.next()
          }
        } else if (this.isEmailFormat) {
          await this.applyPartnerDiscount()
        }
        this.processing = false
        return
      }

      this.showErrorsSwitch()
      this.processing = false
    },
    async applyPartnerDiscount() {
      // check user partner
      let resp = null
      let error = false

      try {
        resp = await functions.httpsCallable('clientPartnerAccountDiscount')({
          email: this.localFormatted.code,
        })
      } catch (err) {
        error = true
        // no user - show error message
        if (err.code === 'already-exists') {
          this.submitError = 'The discount for this user has already been used'
        } else {
          this.submitError = 'No user with this email address found'
        }
      }

      if (error) {
        return
      }

      if (resp?.data?.purchased && resp?.data?.purchasedPartner) {
        // apply full discount
        this.$store.commit('app/SET_DISCOUNT_CODE', {
          ...this.localFormatted,
          type: 'partner',
          price: 0,
        })
        this.next()
        return
      }

      if (resp?.data?.purchased) {
        // apply half discount
        this.$store.commit('app/SET_DISCOUNT_CODE', {
          ...this.localFormatted,
          type: 'partner',
          price: 79,
        })
        this.next()
        return
      }

      // error message - no discount
      this.submitError =
        'User with that email address has not purchased their Will'
      return
    },
    close() {
      this.$emit('close')
    },
    next(id = null) {
      this.$emit('next', id)
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showErrors[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
  },
  computed: {
    buttonType() {
      if (this.processing) {
        return this.btnProcessing
      }

      if (this.isValid) {
        return this.btnActive
      }

      return this.btnInactive
    },
    errorMessages() {
      const msgs = {}
      Object.keys(this.showErrors).forEach((field) => {
        msgs[field] = null
      })

      if (!this.promoCode && !this.isPrepaidFormat && !this.isEmailFormat) {
        msgs.code = 'Invalid code'
      }

      if (this.submitError) {
        msgs.submit = this.submitError
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    isChanged() {
      return !isEqual(this.localFormatted, this.entity_person)
    },
    promoCode() {
      return this.$store.state.app.promoCodes[this.localFormatted.code] || null
    },
    isPrepaidFormat() {
      /* return (
        /^[0-9]{6}$/.test(this.localFormatted.code) ||
        /^[0-9]{7}$/.test(this.localFormatted.code) ||
        /^[A-Za-z]{3}[0-9]{6}$/.test(this.localFormatted.code)
      )*/
      return /^[A-Z0-9]+$/.test(this.localFormatted.code)
    },
    isEmailFormat() {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(this.local.code.replace(/[\s]/g, ''))
    },
    localFormatted() {
      return {
        code: this.isEmailFormat
          ? this.local.code.replace(/[\s]/g, '')
          : this.local.code.replace(/[\s]/g, '').toUpperCase(),
      }
    },
  },
}
</script>

<style lang="scss">
.promo {
  /* input {
    text-transform: uppercase;
  } */
}
</style>
